/* eslint-disable react/jsx-no-target-blank */
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { HashLink } from 'react-router-hash-link'

const navigation = [
  { name: 'Home', href: '#', current: false },
  { name: 'About', href: '#about', current: false },
  { name: 'Tokenomics', href: '#tokenomics', current: false },
  { name: 'Roadmap', href: '#roadmap', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <Disclosure as="nav" className="fixed bg-[#0a0103] bg-opacity-5 z-[100] left-0 w-full backdrop-blur-lg px-2">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl sm:px-2 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-[#c1c1c1] hover:bg-[#0a0103] hover:text-[#c1c1c1] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#c1c1c1] commonanimation">
                  <span className="absolute -inset-0.5" />
                  {/* <span className="sr-only">Open main menu</span> */}
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>


              </div>
              <div className="flex flex-1 items-center  sm:justify-between">
                <div className="flex flex-shrink-0 items-center mt-2">
                  <img
                    className="h-16 rounded-md w-auto sm:block hidden"
                    src="https://ipfs.io/ipfs/QmUGcZrXgmePKGGcFEwZUfK1PETXB3uvMaLTnBdYx3pPMN"
                    alt="memeunion"
                  />
                  <img
                    className="h-16 rounded-md w-auto sm:hidden block"
                    src="https://ipfs.io/ipfs/QmUGcZrXgmePKGGcFEwZUfK1PETXB3uvMaLTnBdYx3pPMN"
                    alt="memeunion"
                  />
                </div>
                <div className="flex mx-auto gap-x-10 sm:hidden">
                  <a href="https://t.me/thememeunion_chat" target="_blank">
                    <img src="https://memeunion.s3.eu-west-2.amazonaws.com/Telegram-logo.svg" className="w-8 h-8" alt=""></img>
                  </a>

                  <a href="https://twitter.com/TheMemeUnion" target="_blank">
                    <img src="https://memeunion.s3.eu-west-2.amazonaws.com/X-logo.svg" className="w-8 h-8 bg-black p-1.5 rounded" alt=""></img>
                  </a>
                </div>
                <div className="hidden sm:ml-4 sm:block">
                  <div className="flex space-x-4 items-center whitespace-nowrap">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'text-[#c1c1c1]' : 'text-[#c1c1c1] hover:text-opacity-50 commonanimation',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                    <HashLink smooth to="#presale" className="text-xs  w-fit h-fit px-5 py-2.5 bg-[#c1c1c1] text-[#311019] hover:scale-105 ani cursor-pointer font-medium rounded-full uppercase">BUY $MU Presale</HashLink>
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-[#0a0103] text-[#c1c1c1]' : 'text-[#c1c1c1] hover:bg-[#0a0103] commonanimation hover:text-[#c1c1c1]',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
