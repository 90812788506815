import { CgBorderStyleDotted } from "react-icons/cg";

function Presale() {

    return (
        <section id="presale" className="#tokenomics pt-20 mx-auto relative">
            <h1 className="font-bold text-3xl text-center py-1 border-b border-white border-opacity-5 mb-3 uppercase tracking-widest ">How to buy in presale?</h1>

            <div className="font-semibold p-3 border-2 border-[#8f594f] w-fit mx-auto rounded-lg overflow-hidden">
                <h3 className="text-md font-bold tracking-wider uppercase">Step 1</h3>
                <div>Send $SOL from your personal DeFi wallet address to <div className="text-xs text-center px-2 py-1.5 mt-1 border border-white border-opacity-20 rounded">82QwrCRZ7n3CFCUoopgJE5WKi6zw15VVBy1xhQ2cEvgA</div></div>
                <button className="text-xs flex mx-auto px-3 py-1.5 mt-2 hover:bg-opacity-80 ani bg-[#c1c1c1] text-black rounded" onClick={() => navigator.clipboard.writeText('82QwrCRZ7n3CFCUoopgJE5WKi6zw15VVBy1xhQ2cEvgA')}>
                    Copy Wallet Address
                </button>
            </div>

            <CgBorderStyleDotted className="w-52 text-2xl rotate-90 mx-auto text-[#8f594f]" />
            <CgBorderStyleDotted className="w-52 text-2xl rotate-90 mx-auto text-[#8f594f]" />

            <div className="font-semibold p-3 border-2 border-[#8f594f] w-fit mx-auto rounded-lg overflow-hidden">
                <h3 className="text-md font-bold tracking-wider uppercase">Step 2</h3>
                <p>We will airdrop your $MU tokens within 24 hours and launch liquidity pool on Raydium</p>
            </div>

            <div className="p-3 bg-white bg-opacity-10 w-fit h-fit mx-auto mt-6 rounded-xl">
                <img src="https://memeunion.s3.eu-west-2.amazonaws.com/its-very-simple-eric-cartman.gif" alt="" className="rounded-lg"></img>
            </div>
        </section>
    )
}

export default Presale;