/* eslint-disable react/jsx-no-target-blank */
import { RiDoubleQuotesL } from "react-icons/ri";
export default function Hero() {
    return (
        <>
            <div className='absolute w-[300px] h-[300px] rounded-full -z-10 bottom-5 right-5 blur-[180px] bg-pink-500'></div>
            <div className='absolute w-[300px] h-[300px] rounded-full -z-10 top-5 left-5 blur-[180px] bg-rose-500'></div>
            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/mu-logo.png" className="w-48 mx-auto sm:mt-24 mb-6 mt-20" alt=""></img>
            <div className="mx-auto sm:px-6 h-fit tracking-wide gap-2 grid sm:grid-cols-2 grid-cols-1 w-full">
                <div className="sm:p-5 p-3">
                    <h1 className="font-bold sm:text-5xl md:text-3xl text-3xl font-serif">
                        A straightforward platform for the community, creators and sharers.
                    </h1>
                    <div className="mt-6 tracking-wide sm:text-md text-sm font-medium">
                        <p>The Meme Union empowers creators and sharers alike, recognising the value of their time and efforts. Every edit is a unique creation, worthy of support and reward.</p>
                        <p className="uppercase text-md font-bold tracking-[2px] mt-3">It's time to earn on your share!</p>
                    </div>

                    <div className="flex h-fit my-6 font-semibold gap-x-4 w-fit">
                        <a href="https://t.me/thememeunion_chat" target="_blank" className="px-5 py-2.5 rounded-full text-[#ffffff] cursor-pointer bg-[#0088cc] hover:scale-105 ani">Join Telegram</a>
                        <a href="https://twitter.com/TheMemeUnion" target="_blank" className="px-5 py-2.5 rounded-full text-black cursor-pointer bg-[#c1c1c1] hover:scale-105 ani">Follow X</a>
                    </div>
                </div>

                <div className="p-3 bg-white bg-opacity-10 w-fit h-fit mx-auto rounded-xl">
                    <img src="https://memeunion.s3.eu-west-2.amazonaws.com/you-da.gif" alt="" className="rounded-lg"></img>
                </div>
            </div>
            <div className="px-4 w-fit h-fit mx-auto mt-6">
                <RiDoubleQuotesL className="sm:text-7xl text-5xl -mb-8 -z-10 opacity-10" />
                <h1 className="font-extrabold font-serif mx-auto text-3xl italic">If you spend time editing something, you are creating something.</h1>
            </div>
            {/* <div className="mx-auto p-4 my-24 min-h-[50vh] h-fit tracking-wide grid sm:grid-cols-2 grid-cols-1 items-center border rounded-lg border-white border-opacity-10 mt-20 sm:w-[80%] relative">
                
                <div className="p-3 bg-white bg-opacity-10 w-fit h-fit mx-auto rounded-xl">
                    <img src="https://media1.tenor.com/m/Ft71uoGyHLEAAAAd/cat-moar.gif" alt="" className="rounded-lg"></img>
                </div>
            </div> */}



        </>
    )
}