
export default function Tokenomics() {

    return (
        <section id="tokenomics" className="pt-16 relative">
            <div className='absolute w-[300px] h-[300px] rounded-full -z-10 bottom-5 left-0 blur-[180px] bg-pink-500'></div>
            <div className='absolute w-[300px] h-[300px] rounded-full -z-10 top-5 right-0 blur-[180px] bg-rose-500'></div>
            <div className="mx-auto">
                <h1 className="font-bold text-3xl text-center py-1 border-b border-white border-opacity-5 mt-10 uppercase tracking-widest">Tokenomics</h1>
                <div className="grid sm:grid-cols-5 grid-cols-1 divide-x-4 divide-[#dcc5a4] gap-3 p-5 font-mono w-full sm:text-center justify-center">
                    <div className="p-3 border-l-4 border-[#dcc5a4] ">
                        <h2>Network</h2>
                        <p>Solana</p>
                    </div>

                    <div className="p-3">
                        <h2>Symbol</h2>
                        <p>$MU</p>
                    </div>

                    <div className="p-3">
                        <h2>Total Supply</h2>
                        <p>1,000,000,000 $MU</p>
                    </div>

                    <div className="p-3">
                        <h2>Buy/Sell Tax</h2>
                        <p>0%</p>
                    </div>

                    <div className="p-3">
                        <h2>Mint/Freeze</h2>
                        <p>Revoked</p>
                    </div>
                </div>
                <div className="bg-white bg-opacity-5 font-sans rounded-lg sm:p-10 p-3 my-20 ">
                    <div className="font-bold text-3xl text-center uppercase tracking-widest mb-10">Distribution</div>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 w-fit items-center">
                        <div className="grid grid-cols-1 gap-2 font-mono w-full justify-center">
                            <div className="p-3">
                                <h2 className="uppercase">Creators/Sharers Fund</h2>
                                <p className="font-bold">10%</p>
                            </div>

                            <div className="p-3">
                                <h2 className="uppercase">Liquidity Pool</h2>
                                <p className="font-bold">30%</p>
                            </div>

                            <div className="p-3">
                                <h2 className="uppercase">Presale</h2>
                                <p className="font-bold">50%</p>
                            </div>
                            <div className="p-3">
                                <h2 className="uppercase">Marketing and CEX Listings</h2>
                                <p className="font-bold">10%</p>
                            </div>

                            <div className="p-3">
                                <h2 className="uppercase">Liquidity Pool</h2>
                                <p className="font-bold">Locked Forever</p>
                            </div>
                        </div>
                        <div className="p-3 bg-white bg-opacity-10 w-fit h-fit mx-auto mt-6 rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/candies-mnm.gif" alt="" className="rounded-lg"></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}