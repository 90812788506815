import './index.css';
import Hero from './components/hero';
import About from './components/about';
import Tokenomics from './components/tokenomic';
import Presale from './components/presale';
import Roadmap from './components/roadmap';
import Footer from './components/footer';
import Header from './components/header';
import { HashLink } from 'react-router-hash-link';

export default function App() {
  return (
    <>
      <Header />
      <div className='p-3'>
        <Hero />
        <About />
        <Tokenomics />
        <Presale />
        <Roadmap />
        <Footer />
      </div>
      <HashLink smooth to="#presale" className="fixed bottom-3 right-3 sm:hidden text-xs w-fit h-fit px-5 py-2.5 bg-[#c1c1c1] text-[#311019] hover:scale-105 ani cursor-pointer font-medium rounded-full uppercase">BUY $MU Presale</HashLink>
    </>
  );
}