/* eslint-disable react/jsx-no-target-blank */

export default function Footer() {

    return (
        <>
            <section id="#footer" className="border-white border border-opacity-5 p-5 rounded-lg my-16 text-center relative">
                <div className='absolute w-full h-[300px] -z-10 bottom-5 right-5 blur-[150px] bg-red-950'></div>
                <img className="w-52 mx-auto" src="https://memeunion.s3.eu-west-2.amazonaws.com/mu-logo.png" alt="" />
                <h1 className="font-bold text-2xl mt-3 text-center uppercase tracking-widest">Join the Meme Union</h1>
                <p className="mb-4 text-base font-normal text-[#c1c1c1] text-opacity-60">Don't miss out on the latest updates! Stay connected and up-to-date with all the information.</p>
                <div className="flex items-start my-6 font-semibold gap-x-4 w-fit mx-auto h-16">
                    <a href="https://t.me/thememeunion_chat" target="_blank" className="px-5 py-2.5 rounded-full text-[#ffffff] cursor-pointer bg-[#0088cc] hover:scale-105 ani">Join Telegram</a>
                    <a href="https://twitter.com/TheMemeUnion" target="_blank" className="px-5 py-2.5 rounded-full text-black cursor-pointer bg-[#c1c1c1] hover:scale-105 ani">Follow X</a>
                </div>
                <div className="p-3 bg-white bg-opacity-10 w-fit h-fit mx-auto mt-6 rounded-lg">
                    <img src="https://memeunion.s3.eu-west-2.amazonaws.com/you-must-become-one-of-us-sunny-johnson.gif" alt="" className="rounded-lg"></img>
                </div>
            </section>
            <div className="text-xs text-center my-10 tracking-wider">Copyright © 2024 The Meme<strong>Union</strong>. All rights reserved.</div>
        </>
    )
}