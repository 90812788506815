/* eslint-disable react/jsx-no-target-blank */

export default function Roadmap() {

    return (
        <section id="roadmap" className="pt-16 relative">
            <div className='absolute w-[300px] h-[300px] rounded-full -z-10 bottom-5 right-0 blur-[180px] bg-pink-500'></div>
            <div className='absolute w-[300px] h-[300px] rounded-full -z-10 top-5 left-0 blur-[180px] bg-rose-500'></div>
            <div className="mx-auto">
                <h1 className="font-bold text-3xl text-center py-2 border-b border-white border-opacity-10 mt-10 uppercase tracking-widest">Roadmap</h1>
                <ul className="relative border-l pt-10 border-[#c1c1c1] w-full">
                    <li className="mb-10 ms-4">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                        <time className="uppercase font-mono mb-1 text-sm font-normal leading-none">March 2024</time>
                        <h3 className="text-lg font-semibold">Initial Stage - Project Setup</h3>
                        <p className="mb-4 text-base font-normal text-[#c1c1c1] text-opacity-60">Bring the vision to life - create a contract, ignite presale campaign, spread the word with ad campaigns and airdrops, and launch the Meme<strong>Union</strong> project.</p>
                        <div className="p-3 my-4 bg-white bg-opacity-10 w-fit h-fit rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/spaghetti-naptime.gif" alt="" className="rounded-lg"></img>
                        </div>
                        <p className="mb-4 text-base font-normal items-center sm:flex">
                            Submit Listing Applications on
                            <div className="flex items-center my-2"> <img alt="" className="w-6 h-6 mx-1  opacity-100" src="https://seeklogo.com/images/C/coinmarketcap-logo-064D167A0E-seeklogo.com.png"></img>CoinMarketCap</div>
                            <div className="flex items-center"> <img alt="" className="w-7 h-7 mx-1 opacity-100" src="https://support.coingecko.com/hc/article_attachments/4499575478169/CoinGecko_logo.png"></img>Coingecko</div>
                        </p>
                    </li>
                    <li className="mb-10 ms-4 pt-10">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                        <time className="uppercase font-mono mb-1 text-sm font-normal leading-none">April 2024</time>
                        <h3 className="text-lg font-semibold">Creators and Spreaders Dashboard UI design in Figma</h3>
                        <p className="mb-4 text-base font-normal text-[#c1c1c1] text-opacity-60">All pages and components are first designed in Figma and kept in parity with the updated project.</p>
                        <a target="_blank" href="https://www.figma.com/file/vqBcZw6xCsGmXBnFZvFMik/MU-Dashboard?type=design&node-id=0%3A1&mode=design&t=h9h44bvE0hWMFbKY-1" className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border rounded-lg hover:bg-gray-100 hover:text-blue-700">Dashboard UI - Figma <svg className="w-3 h-3 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg></a>
                        <div className="p-3 my-4 bg-white bg-opacity-10 w-fit h-fit rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/designer-developer.gif" alt="" className="rounded-lg"></img>
                        </div>
                    </li>
                    <li className="mb-10 ms-4 pt-10">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                        <time className="uppercase font-mono mb-1 text-sm font-normal leading-none">April 2024</time>
                        <h3 className="text-lg font-semibold">Submit $MU Listing Applications in CEX</h3>
                        <p className="mb-4 text-base font-normal text-[#c1c1c1] text-opacity-60">Begin our journey with dozens of CEX listings, including the top five exchanges.</p>
                        <div className="p-3 my-4 bg-white bg-opacity-10 w-fit h-fit rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/shooting-lasers-ikaris.gif" alt="" className="rounded-lg"></img>
                        </div>
                    </li>
                    <li className="mb-10 ms-4 pt-10">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                        <time className="uppercase font-mono mb-1 text-sm font-normal leading-none">May 2024</time>
                        <h3 className="text-lg font-semibold">Release Creators/Spreaders and NFT Minting Dashboard</h3>
                        <p className="mb-4 text-base font-normal text-[#c1c1c1] text-opacity-60">Unlocking the power of the MU Dashboard and start sharing your creativity with the world. Simply connect your wallet and share existing clipart and gifs on your social media to earn $MU. As a creator, you can even mint NFTs for your creations and earn a fee from every share made by anyone. The possibilities are endless.</p>
                        <div className="p-3 my-4 bg-white bg-opacity-10 w-fit h-fit rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/angry-pitbull-club-apc.gif" alt="" className="rounded-lg"></img>
                        </div>
                    </li>
                    <li className="mb-10 ms-4 pt-10">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                        <time className="uppercase font-mono mb-1 text-sm font-normal leading-none">Q3 2024</time>
                        <h3 className="text-lg font-semibold">Develop and Release - MU Advanced Version Dashboard</h3>
                        <p className="mb-4 text-base font-normal text-[#c1c1c1] text-opacity-60">Expanding the MU dashboard with Ads Manager, including impression, reactions, and share counters, integrating more social media platforms, and enabling commercial client account creation.</p>
                        <div className="p-3 my-4 bg-white bg-opacity-10 w-fit h-fit rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/remove-ads-rizvanov.gif" alt="" className="rounded-lg"></img>
                        </div>
                    </li>
                    <li className="mb-10 ms-4 pt-10">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                        <time className="uppercase font-mono mb-1 text-sm font-normal leading-none">Q4 2024</time>
                        <h3 className="text-lg font-semibold">Research and Plan - More Ad Tools</h3>
                        <div className="p-3 my-4 bg-white bg-opacity-10 w-fit h-fit rounded-xl">
                            <img src="https://memeunion.s3.eu-west-2.amazonaws.com/research-lab.gif" alt="" className="rounded-lg"></img>
                        </div>
                    </li>
                </ul>
            </div>
        </section >
    )
}