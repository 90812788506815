
function About() {

    return (
        <section id="about" className="pt-20">
            <div className="mx-auto h-fit bg-white bg-opacity-5 rounded-lg sm:p-10 p-4">
                <h1 className="font-bold text-5xl text-center uppercase tracking-widest">About</h1>
                <div className="grid sm:grid-cols-2 grid-cols-1 sm:mt-10 mt-5 gap-5">
                    <div className="mt-6 tracking-wide sm:text-md text-sm font-medium">
                        <p>Do you have a knack for editing humorous statements? Congratulations, you're a creator! The Meme<strong>Union</strong> is calling your name. Join now and discover countless opportunities you have to inspire and make others laugh.</p>
                        <p className="my-4">By sharing memes on your social media account, you are already a sharer! Keep up the good work, because now you will be rewarded for every share you make on social media.</p>
                        <p className="my-10 font-serif font-bold text-xl">Unleash your true potential and earn what you truly deserve with the Meme Union ($MU) token on the Solana network.</p>
                        <div className="p-3 overflow-hidden border border-white border-opacity-20 rounded-xl mt-10">
                            <p className="font-bold text-xl">MemeUnion Contract Address</p>
                            <p className="font-semibold text-[11px]">Gvs5cPDqPv6ctLysqNddk4PDyT62TViuKkyh3MqRtAXe</p>
                            <button className="text-sm flex select-none px-3 py-2 mt-2 hover:bg-opacity-80 ani bg-[#c1c1c1] text-black rounded" onClick={() => navigator.clipboard.writeText('Gvs5cPDqPv6ctLysqNddk4PDyT62TViuKkyh3MqRtAXe')}>
                                Copy Contract Address
                            </button>
                        </div>
                    </div>

                    <div className="p-3 bg-white bg-opacity-10 w-fit h-fit mx-auto rounded-xl">
                        <img src="https://memeunion.s3.eu-west-2.amazonaws.com/are-we-clear-eric-cartman.gif" alt="" className="rounded-lg"></img>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;